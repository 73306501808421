(function($){
    $.fn.toggleButton = function () {
        function toggleButton(button) {
            if (button.hasClass('js-toggle-button')) {
                button.toggleClass('active');
            }

            button.find('.js-toggle-button').toggleClass('active');
        }

        function doRequest(url)
        {
            var self = $(this);

            self.addClass('loading');
            var data = self.data();
            data['enabled'] = self.find('.js-toggle-button')
                .first()
                .hasClass('active') ? 0 : 1;
            delete data['url'];

            $.ajax({
                'method': 'post',
                'url': url,
                'dataType': 'json',
                'data': data
            }).done(function (data) {
                if (!data.success) {
                    var message = 'Не удалось выполнить ' + (data['enabled']?'отписку':'подписку') + '. Попробуйте выполнить запрос позднее.';
                    if (data.message) {
                        message = data.message;
                    }
                    $('.subscribe-error-dialog').find('h3').text('Ошибка!');
                    $('.subscribe-error-dialog').find('.desc').text(data.message);
                    $('.subscribe-error-dialog').fadeToggle('slow');
                    $('.unsubscribe-dialog').hide();

                    return false;
                }

                if (data.action === 'reload') {
                    window.location.reload();
                }

                toggleButton(self);
            }).always(function () {
                self.removeClass('loading');
            });
        }

        function request(url) {
            var self = $(this);
            var enable = self.find('.js-toggle-button')
                .first()
                .hasClass('active') ? 0 : 1;

            if (!enable && $('.unsubscribe-dialog').length) {
                $('.unsubscribe-dialog').find('.popup_submit').off('click').click(function(){
                    doRequest.call(self, url);
                    return false;
                });
                $('.unsubscribe-dialog').fadeToggle('slow');
                return;
            }
            doRequest.call(self, url);

        }

        function init() {
            $(this).off('click').on('click', function () {
                var url = $(this).data('url');

                if ($(this).hasClass('loading')) {
                    return false;
                }

                if (url) {
                    request.call(this, url);
                } else {
                    toggleButton($(this));
                }

                return false;
            });
        }

        return this.each(function () {
            init.call(this);
        });

    };
})(jQuery);
